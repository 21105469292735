import {Button, ButtonGroup, IconButton, InputAdornment, TextField, Typography} from "@mui/material";
import {useState} from "react";
import {MoreVert} from "@mui/icons-material";

export default function Question(props) {
    const {question, beginEdit, deleteQuestion, saveQuestion} = props;

    const [text, setText] = useState(question.question);
    const [open, setOpen] = useState(false);

    return (
        <TextField
            size="small"
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <Typography
                            mr="10px"
                        >
                            {question.key}
                        </Typography>
                    </InputAdornment>
                ),
                endAdornment: (
                    <InputAdornment position="end">
                        <ButtonGroup
                            size="small"
                            sx={{
                                display: { xs: open ? 'initial' : 'none', md: 'initial', },
                            }}
                        >
                            <Button
                                onClick={() => saveQuestion(question.key, text, question.responseType)}
                            >
                                Save
                            </Button>
                            <Button
                                onClick={() => beginEdit(question.key)}
                            >
                                Edit
                            </Button>
                            <Button
                                color="error"
                                onClick={() => deleteQuestion(question.key)}
                            >
                                Delete
                            </Button>
                        </ButtonGroup>
                        <IconButton
                            onClick={() => setOpen(open => !open)}
                            sx={{
                                display: { xs: 'initial', md: 'none', },
                                height: '40px',
                                width: '40px',
                            }}
                        >
                            <MoreVert />
                        </IconButton>
                    </InputAdornment>
                )
            }}
            value={text}
            onChange={(e) => setText(e.target.value)}
            fullWidth
            sx={{
                mb: '10px',
                '.MuiInputBase-root': {
                    pr: '5px'
                }
            }}
        />
    );
}