import {Container} from "./Container";
import {useNavigate} from "react-router-dom";
import {Menu, MenuItem, Stack, Typography} from "@mui/material";
import useUser from "../../useUser";
import {useState} from "react";
import {KeyboardArrowDown} from "@mui/icons-material";
import useToken from "../../useToken";
import {Ghostwriter} from "ghostwriter";
import AvatarImage from "../../assets/avatar.jpg";

export default function Header() {
    const navigate = useNavigate();

    const [token, , clearToken] = useToken();
    const [user, refreshUser] = useUser();

    const [menuAnchor, setMenuAnchor] = useState(null);

    const handleAccountClick = (e) => {
        if (user?.firstName === undefined) {
            navigate('/account/login');
        }

        setMenuAnchor(e.currentTarget);
    };

    const closeMenu = () => {
        setMenuAnchor(null);
    };

    const handleLogout = () => {
        closeMenu();

        Ghostwriter.account.logout(token)
            .then(() => {
                clearToken();
                refreshUser();
                navigate('/');
                window.location.reload();
            });
    };

    return (
        <Container>
            <Typography
                container="h1"
                fontSize="35pt"
                fontWeight="700"
                onClick={() => navigate('/')}
                sx={{
                    cursor: 'pointer',
                }}
            >
                Ghostwriter
            </Typography>

            <Stack
                direction="row"
                alignItems="center"
                onClick={handleAccountClick}
                sx={{
                    cursor: 'pointer',
                }}
                spacing={1}
            >
                <Typography
                    container="p"
                    fontSize="20pt"
                    fontWeight="100"
                    sx={{
                        display: { xs: 'none', sm: 'initial', },
                    }}
                >
                    {user?.firstName ? `Welcome, ${user?.firstName}` : 'Login '}
                    {user?.firstName && <KeyboardArrowDown />}
                </Typography>
                <img
                    className="avatar"
                    src={`https://mystoryapi.maxgoddard.dev/cdn/user/profile/${user?.picture}.jpeg`}
                    onError={(e) => {
                        const {currentTarget} = e;

                        currentTarget.onError = undefined;
                        currentTarget.src = AvatarImage;
                    }}
                    alt="Avatar"
                />
            </Stack>
            <Menu
                id="basic-menu"
                anchorEl={menuAnchor}
                open={Boolean(menuAnchor)}
                onClose={closeMenu}
                sx={{
                    width: '300px',
                    '& .MuiPaper-root': {
                        width: '300px',
                    },
                }}
                MenuListProps={{
                    'aria-labelledby': 'text',
                }}
            >
                <MenuItem onClick={() => navigate('/account')}>My account</MenuItem>
                <MenuItem onClick={() => navigate('/story')}>Write my story</MenuItem>
                {['administrator', 'moderator'].includes(user?.userLevel) &&
                    <MenuItem onClick={() => navigate('/admin')}>Administration</MenuItem>
                }
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
        </Container>
    );
}