import {Alert, Button, Typography} from "@mui/material";
import {Container} from "./Container";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {Ghostwriter} from "ghostwriter";

export default function AccountVerify() {
    const navigate = useNavigate();
    const { token } = useParams();

    const [alert, setAlert] = useState("");
    const [verified, setVerified] = useState(false);

    useEffect(() => {
        Ghostwriter.account.verifyEmail(token)
            .then(() => setVerified(true))
            .catch((err) => setAlert(err.message));
    }, [token, setAlert, setVerified]);

    return (
        <Container>
            <Typography
                component="h1"
                mt="0"
                mb={alert ? '20px' : '40px'}
                fontSize="40px"
                fontWeight="100"
            >
                {verified ? 'Account verified!' : 'Verifying...'}
            </Typography>
            {alert &&
                <Alert
                    severity="error"
                    onClose={() => setAlert(null)}
                    sx={{
                        mb: '20px',
                    }}
                >
                    {alert}
                </Alert>
            }
            {verified &&
                <Button
                    variant="contained"
                    onClick={() => navigate('/')}
                >
                    Home
                </Button>
            }
        </Container>
    );
}