import {Container} from "./Container";
import AdminMenu from "../../components/AdminMenu";

export default function Admin() {
    document.title = 'Administration - Ghostwriter';

    return (
        <Container>
            <AdminMenu />
        </Container>
    );
}