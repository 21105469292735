import {useEffect, useState} from "react";

export default function useToken() {
    const [token, setToken] = useState(localStorage.getItem('token'));

    const refreshToken = () => setToken(localStorage.getItem('token'));

    const clearToken = () => {
        localStorage.removeItem('token');
        setToken(null);
    };

    useEffect(() => {
        if (token) {
            localStorage.setItem('token', token);
        } else {
            refreshToken();
        }
    }, [token, setToken]);

    return [token, setToken, clearToken];
}