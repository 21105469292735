import styled from "styled-components";

export const Container = styled.div`
    position: relative;
    cursor: pointer;
    
    img {
        border: hidden;
        border-radius: 100%;
        
        width: 200px;
        height: 200px;
    }
    
    svg {
        display: none;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 80px;
        height: 80px;
        transform: translate(-50%, -50%);
        color: #eeeeee;
        filter: drop-shadow(0 0 3px #333);
    }
    
    &:hover {
        svg {
            display: initial;
        }
        
        img {
            filter: brightness(0.7);
        }
    }
`;