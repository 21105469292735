import {Container} from "./Container";
import {
    Box,
    IconButton,
    Typography,
    Container as MContainer,
    Button, TextField, Modal, Select, MenuItem, FormControl, InputLabel
} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import AdminMenu from "../../../components/AdminMenu";
import {useEffect, useState} from "react";
import {Ghostwriter} from "ghostwriter";
import useToken from "../../../useToken";
import Question from "./Question";

export default function AdminQuestions() {
    document.title = 'Questions Administration - Ghostwriter';

    const [token] = useToken();

    const [questions, setQuestions] = useState([]);

    const [editMenuOpen, setEditMenuOpen] = useState(false);
    const [key, setKey] = useState(null);
    const [question, setQuestion] = useState('');
    const [type, setType] = useState('text');

    const refreshQuestions = () => {
        Ghostwriter.story.questions()
            .then(setQuestions);
    };

    useEffect(refreshQuestions, [setQuestions]);

    const beginCreate = () => {
        setKey(null);
        setQuestion('');
        setType('text');

        setEditMenuOpen(true);
    };

    const beginEdit = (key) => {
        const editedQuestion = questions.find(e => e.key === key);

        setKey(key);
        setQuestion(editedQuestion.question);
        setType(editedQuestion.responseType);

        setEditMenuOpen(true);
    };

    const submitQuestion = () => {
        if (key) {
            Ghostwriter.admin.question.edit(
                key,
                question,
                type,
                token,
            ).then(refreshQuestions);
        } else {
            Ghostwriter.admin.question.create(
                question,
                type,
                token,
            ).then(refreshQuestions);
        }

        setEditMenuOpen(false);
    };

    const deleteQuestion = (key) => {
        Ghostwriter.admin.question.delete(key, token)
            .then(refreshQuestions);
    };

    const saveQuestion = (key, question, responseType) => {
        Ghostwriter.admin.question.edit(
            key,
            question,
            responseType,
            token
        ).then(refreshQuestions);
    };

    return (
        <Container>
            <AdminMenu />
            <MContainer
                sx={{
                    ml: { sm: '300px', },
                    maxWidth: { sm: 'calc(100% - 400px)', },
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'flex-start',
                    }}
                >
                    <Typography
                        container="h1"
                        fontSize="20pt"
                        mb="20px"
                    >
                        List of questions
                    </Typography>
                    <IconButton
                        variant="outlined"
                        size="small"
                        onClick={beginCreate}
                    >
                        <AddIcon fontSize="large"  />
                    </IconButton>
                </Box>

                {questions.map((question) => (
                    <Question
                        key={question.question}
                        beginEdit={beginEdit}
                        deleteQuestion={deleteQuestion}
                        saveQuestion={saveQuestion}
                        question={question}
                    />
                ))}
            </MContainer>
            <Modal
                open={editMenuOpen}
                onClose={() => setEditMenuOpen(false)}
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '450px',
                    bgcolor: 'background.paper',
                    borderRadius: '5px',
                    boxShadow: 24,
                    p: 4,
                }}>
                    <Typography
                        fontSize="18pt"
                        mb="20px"
                    >
                        {key === null ? 'New' : 'Edit'} question
                    </Typography>
                    <TextField
                        label="Question"
                        size="small"
                        value={question}
                        onChange={(e) => setQuestion(e.target.value)}
                        fullWidth
                        sx={{
                            mb: '20px',
                        }}
                    />
                    <FormControl
                        fullWidth
                        sx={{
                            mb: '20px',
                        }}
                    >
                        <InputLabel>Response Type</InputLabel>
                        <Select
                            id="demo-simple-select"
                            label="Response Type"
                            value={type}
                            onChange={(e) => setType(e.target.value)}
                            size="small"
                        >
                            <MenuItem value="text">Text</MenuItem>
                            <MenuItem value="date">Date</MenuItem>
                        </Select>
                    </FormControl>
                    <Button
                        variant="contained"
                        size="medium"
                        onClick={submitQuestion}
                    >
                        Save
                    </Button>
                </Box>
            </Modal>
        </Container>
    );
}