import {Box, Button, ButtonGroup, CircularProgress, Menu, MenuItem, Tooltip} from "@mui/material";
import {
    Add, ArrowDropDown,
    FormatAlignCenter, FormatAlignJustify,
    FormatAlignLeft, FormatAlignRight,
    FormatBold,
    FormatItalic, FormatListBulleted,
    FormatListNumbered,
    FormatStrikethrough,
    FormatUnderlined,
    Redo, Save,
    Undo
} from "@mui/icons-material";
import {useCurrentEditor} from "@tiptap/react";
import {useEffect, useState} from "react";

export default function MenuBar(props) {
    const {onSave, onEditor} = props;

    const { editor } = useCurrentEditor();

    const [insertMenuAnchor, setInsertMenuAnchor] = useState(null);
    const [textStyleMenuAnchor, setTextStyleMenuAnchor] = useState(null);
    const [saveLoading, setSaveLoading] = useState(false);

    const textStyle =
        editor.isActive('paragraph') ? 'Paragraph' :
        [...new Array(6).keys()]
            .map((_, i) => `Heading ${i + 1}`)
            .filter((tag, i) => editor.isActive('heading', { level: i + 1 }))
            [0];

    useEffect(() => onEditor?.(editor), [onEditor, editor]);

    const doSave = async () => {
        await onSave?.(editor);
        setSaveLoading(false);
    };

    return (
        <Box>
            {/* General Format */}
            <ButtonGroup
                variant="contained"
            >
                <Tooltip title="Toggle Bold">
                    <Button
                        onClick={() => editor.chain().focus().toggleBold().run()}
                        disabled={!editor.can().chain().focus().toggleBold().run()}
                        className={editor.isActive('bold') ? 'active' : ''}
                    >
                        <FormatBold />
                    </Button>
                </Tooltip>
                <Tooltip title="Toggle Italic">
                    <Button
                        onClick={() => editor.chain().focus().toggleItalic().run()}
                        disabled={!editor.can().chain().focus().toggleItalic().run()}
                        className={editor.isActive('italic') ? 'active' : ''}
                    >
                        <FormatItalic />
                    </Button>
                </Tooltip>
                <Tooltip title="Toggle Underline">
                    <Button
                        onClick={() => editor.chain().focus().toggleUnderline().run()}
                        disabled={!editor.can().chain().focus().toggleUnderline().run()}
                        className={editor.isActive('underline') ? 'active' : ''}
                    >
                        <FormatUnderlined />
                    </Button>
                </Tooltip>
                <Tooltip title="Toggle Strikethrough">
                    <Button
                        onClick={() => editor.chain().focus().toggleStrike().run()}
                        disabled={!editor.can().chain().focus().toggleStrike().run()}
                        className={editor.isActive('strike') ? 'active' : ''}
                    >
                        <FormatStrikethrough />
                    </Button>
                </Tooltip>
            </ButtonGroup>

            {/* Style */}
            <ButtonGroup
                variant="contained"
                className="text"
            >
                <Tooltip title="Style">
                    <Button
                        onClick={(e) => setTextStyleMenuAnchor(e.currentTarget)}
                        sx={{
                            width: '140px',
                        }}
                    >
                        {textStyle} <ArrowDropDown />
                    </Button>
                </Tooltip>
            </ButtonGroup>
            <Menu
                anchorEl={textStyleMenuAnchor}
                open={Boolean(textStyleMenuAnchor)}
                onClose={() => setTextStyleMenuAnchor(null)}
                MenuListProps={{
                    dense: true,
                }}
            >
                <MenuItem
                    onClick={() => {
                        setTextStyleMenuAnchor(null);
                        editor.chain().focus().setParagraph().run()
                    }}
                    sx={{
                        background: textStyle === 'Paragraph' ? '#eeeeee' : 'initial',
                    }}
                >
                    <p>Paragraph</p>
                </MenuItem>
                {
                    [...new Array(7).keys()].slice(1).map((i) => (
                        <MenuItem
                            key={`Heading ${i}`}
                            onClick={() => {
                                setTextStyleMenuAnchor(null);
                                editor.chain().focus().setHeading({ level: i }).run();
                            }}
                            sx={{
                                background: textStyle === `Heading ${i}` ? '#eeeeee' : 'initial',
                            }}
                        >
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: `<h${i}>Heading ${i}</h${i}>`
                                }}
                            />
                        </MenuItem>
                    ))
                }
            </Menu>

            {/* Alignment */}
            <ButtonGroup
                variant="contained"
            >
                <Tooltip title="Left Align">
                    <Button
                        onClick={() => editor.chain().focus().setTextAlign('left').run()}
                        disabled={!editor.can().chain().focus().setTextAlign('left').run()}
                        className={editor.isActive({ textAlign: 'left', }) ? 'active' : ''}
                    >
                        <FormatAlignLeft />
                    </Button>
                </Tooltip>
                <Tooltip title="Center Align">
                    <Button
                        onClick={() => editor.chain().focus().setTextAlign('center').run()}
                        disabled={!editor.can().chain().focus().setTextAlign('center').run()}
                        className={editor.isActive({ textAlign: 'center', }) ? 'active' : ''}
                    >
                        <FormatAlignCenter />
                    </Button>
                </Tooltip>
                <Tooltip title="Right Align">
                    <Button
                        onClick={() => editor.chain().focus().setTextAlign('right').run()}
                        disabled={!editor.can().chain().focus().setTextAlign('right').run()}
                        className={editor.isActive({ textAlign: 'right', }) ? 'active' : ''}
                    >
                        <FormatAlignRight />
                    </Button>
                </Tooltip>
                <Tooltip title="Justify">
                    <Button
                        onClick={() => editor.chain().focus().setTextAlign('justify').run()}
                        disabled={!editor.can().chain().focus().setTextAlign('justify').run()}
                        className={editor.isActive({ textAlign: 'justify', }) ? 'active' : ''}
                    >
                        <FormatAlignJustify />
                    </Button>
                </Tooltip>
            </ButtonGroup>

            {/* Special */}
            <ButtonGroup
                variant="contained"
            >
                <Tooltip title="Insert">
                    <Button
                        onClick={(e) => setInsertMenuAnchor(e.currentTarget)}
                    >
                        <Add />
                    </Button>
                </Tooltip>
                <Menu
                    anchorEl={insertMenuAnchor}
                    open={Boolean(insertMenuAnchor)}
                    onClose={() => setInsertMenuAnchor(null)}
                    MenuListProps={{
                        dense: true,
                    }}
                >
                    <MenuItem
                        onClick={() => {
                            setInsertMenuAnchor(null);
                            editor.chain().focus().setHorizontalRule().run();
                        }}
                        disabled={!editor.can().chain().focus().setHorizontalRule().run()}
                    >
                        Horizontal Rule
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            setInsertMenuAnchor(null);
                            editor.chain().focus().setBlockquote().run();
                        }}
                        disabled={!editor.can().chain().focus().setBlockquote().run()}
                    >
                        Blockquote
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            setInsertMenuAnchor(null);
                            editor.chain().focus().setCodeBlock().run();
                        }}
                        disabled={!editor.can().chain().focus().setCodeBlock().run()}
                    >
                        Code Block
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            setInsertMenuAnchor(null);
                            editor.chain().focus().insertContent('—').run();
                        }}
                        disabled={!editor.can().chain().focus().insertContent('—').run()}
                    >
                        Em dash
                    </MenuItem>
                </Menu>

                <Tooltip title="Bulleted List">
                    <Button
                        onClick={() => editor.chain().focus().toggleBulletList().run()}
                        disabled={!editor.can().chain().focus().toggleBulletList().run()}
                        className={editor.isActive('bulletList') ? 'active' : ''}
                    >
                        <FormatListBulleted />
                    </Button>
                </Tooltip>
                <Tooltip title="Numbered List">
                    <Button
                        onClick={() => editor.chain().focus().toggleOrderedList().run()}
                        disabled={!editor.can().chain().focus().toggleOrderedList().run()}
                        className={editor.isActive('orderedList') ? 'active' : ''}
                    >
                        <FormatListNumbered />
                    </Button>
                </Tooltip>
            </ButtonGroup>

            {/* History */}
            <ButtonGroup
                variant="contained"
            >
                <Tooltip title="Undo">
                    <Button
                        onClick={() => editor.chain().focus().undo().run()}
                        disabled={!editor.can().chain().focus().undo().run()}
                    >
                        <Undo />
                    </Button>
                </Tooltip>
                <Tooltip title="Redo">
                    <Button
                        onClick={() => editor.chain().focus().redo().run()}
                        disabled={!editor.can().chain().focus().redo().run()}
                    >
                        <Redo />
                    </Button>
                </Tooltip>
            </ButtonGroup>

            {/* Save */}
            {onSave &&
                <ButtonGroup
                    variant="contained"
                >
                    <Tooltip title="Save">
                        <Button
                            disabled={saveLoading}
                            onClick={doSave}
                        >
                            {
                                saveLoading ?
                                    <CircularProgress size="20px" /> :
                                    <Save />
                            }
                        </Button>
                    </Tooltip>
                </ButtonGroup>
            }
        </Box>
    );
}