import {useEffect, useState} from "react";
import useToken from "./useToken";
import {Ghostwriter} from "ghostwriter";

export default function useUser() {
    const [token] = useToken();
    const [user, setUser] = useState(null);

    const refreshUser = () => {
        if (token) {
            Ghostwriter.user.self(token)
                .then(setUser)
                .catch(console.log);
        }
    }

    useEffect(refreshUser, [token]);

    return [user, refreshUser];
}