import styled from "styled-components";

export const Container = styled.div`
    position: fixed;
    
    top: 0;
    left: 0;
    right: 0;
    
    height: 90px;
    
    padding: 0 50px;
    
    background: #e9e9e9;
    
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    img.avatar {
        width: 48px;
        height: 48px;
        border: hidden;
        border-radius: 100%;
    }
`;
