import {Alert, Button, TextField, Typography} from "@mui/material";
import {Container} from "./Container";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {Ghostwriter} from "ghostwriter";
import useUser from "../../../useUser";

export default function AccountRegister() {
    document.title = 'Register - Ghostwriter';

    const navigate = useNavigate();

    const [user] = useUser();

    const [alert, setAlert] = useState("");

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [passwordConfirmation, setPasswordConfirmation] = useState("");

    const [showConfirmation, setShowConfirmation] = useState(false);

    useEffect(() => {
        if (user && user?.firstName) {
            navigate('/');
        }
    }, [user, navigate]);

    const requestRegister = () => {
        if (firstName.length < 1 || firstName.length > 32) {
            setAlert("First name must be between 1 and 32 characters.");
            return;
        }

        if (lastName.length < 1 || lastName.length > 32) {
            setAlert("Last name must be between 1 and 32 characters.");
            return;
        }

        if (email.length < 5 || lastName.length > 64) {
            setAlert("Email must be between 5 and 64 characters.");
            return;
        }

        if (password.length < 8 || password.length > 64) {
            setAlert("Password must be between 8 and 64 characters.");
            return;
        }

        if (password !== passwordConfirmation) {
            setAlert("Passwords do not match.");
            return;
        }

        Ghostwriter.account.register(email, firstName, lastName, password)
            .then(() => {
                Ghostwriter.account.sendVerificationEmail(email)
                    .then(console.log)
                    .catch((err) => setAlert(err.message));
            })
            .catch((err) => setAlert(err.message));
    };

    useEffect(() => {
        const timeoutId = setTimeout(
            () => setShowConfirmation(password.length > 0),
            300,
        );

        return () => clearTimeout(timeoutId);
    }, [setShowConfirmation, password]);

    return (
        <Container>
            <Typography
                component="h1"
                mt="0"
                mb={alert ? '20px' : '40px'}
                fontSize="40px"
                fontWeight="100"
            >
                Register
            </Typography>
            {alert &&
                <Alert
                    severity="error"
                    onClose={() => setAlert(null)}
                    sx={{
                        mb: '20px',
                    }}
                >
                    {alert}
                </Alert>
            }
            <TextField
                label="First Name"
                variant="outlined"
                sx={{
                    width: '300px',
                    mb: '30px'
                }}
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
            />
            <TextField
                label="Last Name"
                variant="outlined"
                sx={{
                    width: '300px',
                    mb: '30px'
                }}
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
            />
            <TextField
                label="Email"
                variant="outlined"
                sx={{
                    width: '300px',
                    mb: '30px'
                }}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
                label="Password"
                type="password"
                variant="outlined"
                sx={{
                    width: '300px',
                    mb: '30px',
                }}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
            />
            {showConfirmation &&
                <TextField
                    label="Confirm Password"
                    type="password"
                    variant="outlined"
                    sx={{
                        width: '300px',
                        mb: '30px',
                    }}
                    value={passwordConfirmation}
                    onChange={(e) => setPasswordConfirmation(e.target.value)}
                />
            }
            <Button
                sx={{
                    display: 'block',
                    margin: '0 auto',
                    mb: '30px',
                }}
                onClick={() => navigate('/account/login')}
            >
                Already have an account?
            </Button>
            <Button
                variant="contained"
                onClick={requestRegister}
            >
                Register
            </Button>
        </Container>
    );
}