import Header from "../../components/Header";
import {Container} from "./Container";
import {Typography} from "@mui/material";

export default function Home() {
    document.title = 'Home - Ghostwriter';

    return (
        <Container>
            <Header />

            <Typography
                container="h1"
                fontSize="30pt"
                textAlign="center"
                mt="200px"
            >
                Welcome to Ghostwriter!
            </Typography>
        </Container>
    );
}