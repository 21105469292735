import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Home from "./pages/Home";
import AccountLogin from "./pages/Account/Login";
import AccountRegister from "./pages/Account/Register";
import {createTheme, CssBaseline, ThemeProvider} from "@mui/material";
import AccountVerify from "./pages/Account/Verify";
import Account from "./pages/Account";
import Story from "./pages/Story";
import Admin from "./pages/Admin";
import AdminQuestions from "./pages/Admin/Questions";

const theme = createTheme({});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <BrowserRouter basename={process.env.PUBLIC_URL}>
                <Routes path="/">
                    <Route index element={<Home />} />
                    <Route path="account">
                        <Route index element={<Account />} />
                        <Route path="login" element={<AccountLogin />} />
                        <Route path="verify/:token" element={<AccountVerify />} />
                        <Route path="register" element={<AccountRegister />} />
                    </Route>
                    <Route path="admin">
                        <Route index element={<Admin />} />
                        <Route path="questions" element={<AdminQuestions />} />
                    </Route>
                    <Route path="story" element={<Story />} />
                    <Route path='*' element={<>404</>} />
                </Routes>
            </BrowserRouter>
        </ThemeProvider>
    </React.StrictMode>
);
