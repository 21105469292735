import Header from "../../components/Header";
import {Container} from "./Container";
import {Stack, Container as MContainer, Box, Typography, TextField} from "@mui/material";
import useLoginRequired from "../../useLoginRequired";
import ProfileImageSelector from "../../components/ProfileImageSelector";
import {Ghostwriter} from "ghostwriter";
import useToken from "../../useToken";
import AvatarImage from "../../assets/avatar.jpg";
import useUser from "../../useUser";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

export default function Account() {
    document.title = 'Account - Ghostwriter';

    useLoginRequired();

    const [token] = useToken();
    const [user] = useUser();

    const set = (blob) => {
        Ghostwriter.user.picture.set(blob, token)
            .then(console.log);
    };

    return (
        <Container>
            <Header />

            <MContainer
                sx={{
                    width: { sm: 'calc(100% - 50px)', md: '600px', },
                }}
            >
                <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    spacing={5}
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                        mt: '50px'
                    }}
                >
                    <ProfileImageSelector
                        onImageSet={set}
                        baseImageProps={{
                            src: `https://mystoryapi.maxgoddard.dev/cdn/user/profile/${user?.picture}.jpeg`,
                            onError: (e) => {
                                const {currentTarget} = e;

                                currentTarget.onError = undefined;
                                currentTarget.src = AvatarImage;
                            }
                        }}
                    />
                    <Box>
                        <Typography
                            container="h1"
                            fontSize="20pt"
                        >
                            {user?.firstName} {user?.lastName}
                        </Typography>
                    </Box>
                </Stack>

                <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    mt="30px"
                >
                    <Grid sm={4}>
                        <Typography
                            container="p"
                            fontSize="15pt"
                        >
                            First Name
                        </Typography>
                    </Grid>
                    <Grid sm={8}>
                        <TextField
                            size="small"
                            fullWidth
                        />
                    </Grid>

                    <Grid sm={4}>
                        <Typography
                            container="p"
                            fontSize="15pt"
                        >
                            Middle Name
                        </Typography>
                    </Grid>
                    <Grid sm={8}>
                        <TextField
                            size="small"
                            fullWidth
                        />
                    </Grid>

                    <Grid sm={4}>
                        <Typography
                            container="p"
                            fontSize="15pt"
                        >
                            Last Name
                        </Typography>
                    </Grid>
                    <Grid sm={8}>
                        <TextField
                            size="small"
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </MContainer>
        </Container>
    );
}