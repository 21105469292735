import axios from "axios";

export const Ghostwriter = {
    ENDPOINT: 'https://mystoryapi.maxgoddard.dev/v1/',
    getEndpoint: (url) => Ghostwriter.ENDPOINT + url + '/',
    makeRequest: (method, url, data = {}, config = {}) => new Promise((resolve, reject) => {
        axios({
            method,
            url: Ghostwriter.getEndpoint(url),
            data,
            headers: {
                ...(config.headers || {}),
                'Authorization': config.token ? `Bearer ${config.token}` : undefined,
            },
        }).then((res) => {
            const data = res.data;

            if (data.error) {
                reject(data);
            } else {
                resolve(data);
            }
        }).catch((err) => {
            reject({
                error: 'NETWORK_ERROR',
                message: 'Encountered a network error.',
                data: err,
            });
        });
    }),
    account: {
        login: (email, password) =>
            Ghostwriter.makeRequest(
                'post',
                'account/login',
                { email, password },
            ),
        register: (email, firstName, lastName, password) =>
            Ghostwriter.makeRequest(
                'post',
                'account/register',
                { email, firstName, lastName, password },
            ),
        sendVerificationEmail: (email) =>
            Ghostwriter.makeRequest(
                'post',
                'account/send_verification',
                { email },
            ),
        verifyEmail: (token) =>
            Ghostwriter.makeRequest(
                'post',
                'account/verify',
                { token },
            ),
        logout: (token) =>
            Ghostwriter.makeRequest(
                'post',
                'account/logout',
                { token },
            )
    },
    user: {
        self: (token) =>
            Ghostwriter.makeRequest(
                'post',
                'user/self',
                {},
                { token },
            ),
        picture: {
            set: (blob, token) => {
                return Ghostwriter.makeRequest(
                    'post',
                    'user/picture',
                    blob,
                    {
                        token,
                        'Content-Type': 'image/jpeg',
                    },
                );
            }
        }
    },
    story: {
        questions: () =>
            Ghostwriter.makeRequest(
                'get',
                'story/questions',
            ),
        biography: {
            get: (token) =>
                Ghostwriter.makeRequest(
                    'get',
                    'story/biography',
                    {},
                    { token },
                ),
            set: (biography, token) =>
                Ghostwriter.makeRequest(
                    'post',
                    'story/biography',
                    { biography },
                    { token },
                ),
        }
    },
    admin: {
        question: {
            create: (question, responseType, token) =>
                Ghostwriter.makeRequest(
                    'post',
                    'admin/question',
                    { question, type: responseType },
                    { token },
                ),
            edit: (key, question, responseType, token) =>
                Ghostwriter.makeRequest(
                    'post',
                    'admin/question',
                    { key, question, type: responseType },
                    { token },
                ),
            delete: (key, token) =>
                Ghostwriter.makeRequest(
                    'delete',
                    'admin/question',
                    { key },
                    { token },
                ),
        },
    }
};