import {
    AppBar,
    Box, Divider,
    Drawer,
    IconButton,
    ListItem,
    ListItemButton,
    ListItemIcon, ListItemText,
    Toolbar,
    Typography
} from "@mui/material";
import {useNavigate} from "react-router-dom";
import useLoginRequired from "../../useLoginRequired";
import useUser from "../../useUser";
import {useState} from "react";
import List from '@mui/material/List';
import {
    ArrowBack as ArrowBackIcon,
    Menu as MenuIcon,
    Quiz as QuizIcon,
    Home as HomeIcon
} from "@mui/icons-material";

export default function AdminMenu() {
    const navigate = useNavigate();

    useLoginRequired();

    const [user] = useUser();

    if (user && !['administrator', 'moderator'].includes(user.userLevel)) {
        navigate('/account/login');
    }

    const [open, setOpen] = useState(false);

    const drawer = (
        <List>
            <ListItem disablePadding>
                <ListItemButton onClick={() => navigate('/')}>
                    <ListItemIcon>
                        <ArrowBackIcon />
                    </ListItemIcon>
                    <ListItemText primary="Homepage" />
                </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
                <ListItemButton onClick={() => navigate('/admin')}>
                    <ListItemIcon>
                        <HomeIcon />
                    </ListItemIcon>
                    <ListItemText primary="Dashboard" />
                </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
                <ListItemButton onClick={() => navigate('/admin/questions')}>
                    <ListItemIcon>
                        <QuizIcon />
                    </ListItemIcon>
                    <ListItemText primary="Questions" />
                </ListItemButton>
            </ListItem>
        </List>
    )

    return (
        <>
            <AppBar
                position="fixed"
                sx={{
                    width: { sm: `calc(100%)` },
                }}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        sx={{ mr: 2, display: { sm: 'none' } }}
                        onClick={() => setOpen(true)}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap component="div">
                        MyStory Control Panel
                    </Typography>
                </Toolbar>
            </AppBar>
            <Box
                component="nav"
                sx={{ width: { sm: '100%' }, flexShrink: { sm: 0 } }}
                aria-label="mailbox folders"
            >
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Drawer
                    variant="temporary"
                    open={open}
                    onClose={() => setOpen(false)}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '100%' },
                    }}
                >
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => setOpen(false)}>
                                <ListItemIcon>
                                    <ArrowBackIcon />
                                </ListItemIcon>
                                <ListItemText primary="Back" />
                            </ListItemButton>
                        </ListItem>
                    </List>
                    <Divider />
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', sm: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '250px', zIndex: '100', pt: '64px', },
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>
        </>
    );
}