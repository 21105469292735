import styled from "styled-components";

export const Container = styled.div`
    padding: 0;
    
    .tiptap {
        border: solid 1px #333;
        border-radius: 5px;
        height: calc(100vh - 100px);
        overflow-y: scroll;
        padding: 10px;
        margin-top: 10px;
        
        :focus {
            border: hidden;
        }
        
        p {
            margin: 0;
        }
    }
    
    .MuiBox-root {
        display: flex;
        padding-top: 5px;
        
        .MuiButtonGroup-root {
            margin-right: 10px;
            
            .MuiButton-root {
                padding: 6px;
                font-weight: 700;
                font-size: 10pt;
                line-height: 0;
                background: #eeeeee;
                color: #505050;
                border-color: #000000;
                height: 36px;
                
                &.active {
                    background: #505050;
                    color: #eeeeee;
                }
            }
            
            .MuiButton-root[disabled] {
                background: #dddddd;
            }
        }
        
        .MuiButtonGroup-root.text {
            .MuiButton-root {
                padding-left: 10px;
            }
        }
    }
`;