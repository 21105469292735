import {BubbleMenu, EditorProvider} from "@tiptap/react";
import {StarterKit} from "@tiptap/starter-kit";
import Underline from '@tiptap/extension-underline'
import TextAlign from '@tiptap/extension-text-align'
import {Container} from "./Container";
import {Box} from "@mui/material";
import MenuBar from "./MenuBar";

const extensions = [
    StarterKit,
    Underline,
    TextAlign.configure({
        types: ['heading', 'paragraph'],
    }),
];

export default function TextEditor(props) {
    return (
        <Container>
            <EditorProvider
                extensions={extensions}
                slotBefore={<MenuBar {...props} />}
                style={{
                    minHeight: '400px',
                }}
            >
                <BubbleMenu editor={null}><Box></Box></BubbleMenu>
            </EditorProvider>
        </Container>
    )
}