import Header from "../../components/Header";
import {Container} from "./Container";
import {Typography, Container as MContainer, Card} from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import TextEditor from "../../components/TextEditor";
import {useEffect, useState} from "react";
import {Ghostwriter} from "ghostwriter";
import useToken from "../../useToken";

export default function Story() {
    document.title = 'Write Your Story - Ghostwriter';

    const [token] = useToken();

    const [questions, setQuestions] = useState([]);

    const refreshQuestions = () => {
        Ghostwriter.story.questions()
            .then(setQuestions);
    };

    useEffect(refreshQuestions, [setQuestions]);

    const onBiographyEditorAvailable = (editor) => {
        Ghostwriter.story.biography.get(token)
            .then((data) => editor.chain().setContent(data.biography).run())
    };

    const onBiographySave = (editor) => {
        return Ghostwriter.story.biography.set(editor.getHTML(), token);
    };

    return (
        <Container>
            <Header />

            <MContainer>
                <Typography
                    container="h1"
                    fontSize="30pt"
                    mt="20px"
                    mb="20px"
                >
                    My Story
                </Typography>

                <Grid container spacing={4}>
                    <Grid lg={8}>
                        <Typography
                            container="h2"
                            fontSize="20pt"
                            mb="20px"
                        >
                            Biography
                        </Typography>
                        <TextEditor
                            onEditor={onBiographyEditorAvailable}
                            onSave={onBiographySave}
                        />
                    </Grid>
                    <Grid
                        lg={4}
                    >
                        <Typography
                            container="h2"
                            fontSize="20pt"
                            mb="20px"
                        >
                            Answer questions
                        </Typography>

                        {questions.map((question) => (
                            <Card
                                key={question.question}
                                variant="outlined"
                                sx={{
                                    mb: '20px',
                                    cursor: 'pointer',
                                    '&:hover': {
                                        filter: 'drop-shadow(1px 1px 1px #333)',
                                    }
                                }}
                            >
                                <Typography
                                    container="p"
                                    fontSize="15pt"
                                    m="10px 20px"
                                    color="#505050"
                                >
                                    {question.question}
                                </Typography>
                            </Card>
                        ))}
                    </Grid>
                </Grid>

            </MContainer>

        </Container>
    );
}