import {Container} from "./Container";
import AvatarImage from "../../assets/avatar.jpg";
import {Add, Cancel, Check, Edit, Save, Undo} from "@mui/icons-material";
import {useEffect, useRef, useState} from "react";
import {Box, Button, Modal, Stack, Typography} from "@mui/material";
import { styled } from '@mui/material/styles';
import {centerCrop, makeAspectCrop, ReactCrop} from "react-image-crop";

import 'react-image-crop/dist/ReactCrop.css'
import {canvasPreview} from "./canvasPreview";

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const centerAspectCrop = (mediaWidth, mediaHeight, aspect) => {
    return centerCrop(
        makeAspectCrop(
            {
                unit: '%',
                width: 90,
            },
            aspect,
            mediaWidth,
            mediaHeight,
        ),
        mediaWidth,
        mediaHeight,
    )
}

export default function ProfileImageSelector(props) {
    const { onImageSet, baseImageProps } = props;

    const previewCanvasRef = useRef();
    const imageRef = useRef();

    const [selectionModalOpen, setSelectionModalOpen] = useState(false);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);

    const [imageSource, setImageSource] = useState('');
    const [crop, setCrop] = useState();
    const [completedCrop, setCompletedCrop] = useState();

    const openSelectionMenu = () => setSelectionModalOpen(true);
    const closeSelectionMenu = () => setSelectionModalOpen(false);

    const openConfirmMenu = async () => setConfirmModalOpen(true);
    const closeConfirmMenu = () => setConfirmModalOpen(false);

    const generatePreview = () => {
        if (
            confirmModalOpen &&
            completedCrop?.width &&
            completedCrop?.height &&
            imageRef.current &&
            previewCanvasRef.current
        ) {
            // We use canvasPreview as it's much faster than imgPreview.
            canvasPreview(
                imageRef.current,
                previewCanvasRef.current,
                completedCrop
            );
        }
    };

    useEffect(generatePreview, [confirmModalOpen, completedCrop]);

    const onFileSelect = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            setCrop(undefined);

            const reader = new FileReader();

            reader.addEventListener('load', () => setImageSource(reader.result?.toString() || ''));
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    const onImageLoad = (e) => {
        const { width, height } = e.currentTarget;
        setCrop(centerAspectCrop(width, height, 1));
    };

    const saveImage = async () => {
        closeConfirmMenu();
        closeSelectionMenu();
        setImageSource('');

        if (!onImageSet) {
            return;
        }

        const image = imageRef.current;
        const previewCanvas = previewCanvasRef.current;

        if (!image || !previewCanvas || !completedCrop) {
            return;
        }

        // const scaleX = image.naturalWidth / image.width
        // const scaleY = image.naturalHeight / image.height

        const offscreenCanvas = new OffscreenCanvas(
            1024,
            1024
        );

        const ctx = offscreenCanvas.getContext('2d')

        if (!ctx) {
            return;
        }

        ctx.drawImage(
            previewCanvas,
            0,
            0,
            previewCanvas.width,
            previewCanvas.height,
            0,
            0,
            offscreenCanvas.width,
            offscreenCanvas.height,
        );

        const blob = await offscreenCanvas.convertToBlob({
            type: 'image/jpeg',
            quality: 0.5,
        });

        onImageSet(blob);
    };

    return (
        <Container>
            <div
                onClick={openSelectionMenu}
            >
                <img
                    src={AvatarImage}
                    alt=""
                    {...baseImageProps}
                />
                <Edit/>
            </div>
            <Modal
                open={selectionModalOpen}
                onClose={closeSelectionMenu}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: { xs: '100%', sm: '600px', },
                        bgcolor: 'background.paper',
                        borderRadius: '5px',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Stack
                        direction="column"
                        alignItems="center"
                        spacing={2}
                        sx={{
                            marginBottom: '20px',
                        }}
                    >
                        <Typography
                            container="h2"
                            fontSize="20pt"
                        >
                            {imageSource ? 'Crop image' : 'Upload image'}
                        </Typography>
                        {!imageSource &&
                            <Button
                                component="label"
                                role={undefined}
                                variant="contained"
                                tabIndex={-1}
                                startIcon={<Add/>}
                            >
                                Browse
                                <VisuallyHiddenInput
                                    type="file"
                                    onChange={onFileSelect}
                                />
                            </Button>
                        }
                    </Stack>

                    {Boolean(imageSource) &&
                        <Stack
                            direction="column"
                            alignItems="center"
                            spacing={2}
                        >
                            <ReactCrop
                                crop={crop}
                                onChange={(_, percentageCrop) => setCrop(percentageCrop)}
                                onComplete={(c) => setCompletedCrop(c)}
                                aspect={1}
                                minHeight={100}
                            >
                                <img
                                    ref={imageRef}
                                    alt="Crop me"
                                    src={imageSource}
                                    style={{
                                        maxHeight: '500px',
                                        maxWidth: '500px',
                                    }}
                                    onLoad={onImageLoad}
                                />
                            </ReactCrop>
                            <Stack
                                direction="row"
                                spacing={2}
                            >
                                {completedCrop &&
                                    <Button
                                        variant="contained"
                                        onClick={openConfirmMenu}
                                        startIcon={<Check />}
                                    >
                                        Done
                                    </Button>
                                }
                                <Button
                                    component="label"
                                    role={undefined}
                                    variant="contained"
                                    tabIndex={-1}
                                    startIcon={<Add/>}
                                >
                                    Select other
                                    <VisuallyHiddenInput
                                        type="file"
                                        onChange={onFileSelect}
                                    />
                                </Button>
                                <Button
                                    variant="contained"
                                    onClick={closeSelectionMenu}
                                    startIcon={<Cancel />}
                                >
                                    Cancel
                                </Button>
                            </Stack>
                        </Stack>
                    }

                    <Modal
                        open={confirmModalOpen}
                        keepMounted
                    >
                        <Box
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: { xs: '100%', sm: '400px', },
                                bgcolor: 'background.paper',
                                borderRadius: '5px',
                                boxShadow: 24,
                                p: 4,
                            }}
                        >
                            <Stack
                                direction="column"
                                alignItems="center"
                                spacing={4}
                            >
                                <Typography
                                    container="h2"
                                    fontSize="20pt"
                                >
                                    Final picture
                                </Typography>
                                <canvas
                                    ref={previewCanvasRef}
                                    style={{
                                        border: 'hidden',
                                        borderRadius: '100%',
                                        objectFit: 'contain',
                                        width: '192px',
                                        height: '192px',
                                    }}
                                />

                                <Stack
                                    direction="row"
                                    spacing={2}
                                >
                                    <Button
                                        variant="contained"
                                        startIcon={<Save />}
                                        onClick={saveImage}
                                    >
                                        Save
                                    </Button>

                                    <Button
                                        variant="contained"
                                        onClick={closeConfirmMenu}
                                        startIcon={<Undo />}
                                    >
                                        Retry
                                    </Button>
                                </Stack>
                            </Stack>
                        </Box>
                    </Modal>
                </Box>
            </Modal>
        </Container>
    )
}