import {Alert, Backdrop, Button, CircularProgress, TextField, Typography} from "@mui/material";
import {Container} from "./Container";
import {useNavigate} from "react-router-dom";
import {Ghostwriter} from "ghostwriter";
import {useEffect, useState} from "react";
import useToken from "../../../useToken";
import useUser from "../../../useUser";

export default function AccountLogin() {
    document.title = 'Login - Ghostwriter';

    const navigate = useNavigate();

    const [, setToken] = useToken();
    const [user] = useUser();

    const [alert, setAlert] = useState("");

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (user && user.firstName) {
            navigate('/');
        }
    }, [user, navigate]);

    const requestLogin = () => {
        if (email.length > 0 && password.length > 0) {
            (async () => {
                setLoading(true);

                await Ghostwriter.account.login(email, password)
                    .then((data) => {
                        setToken(data.account.token);
                        window.location.reload();
                    })
                    .catch((err) => setAlert(err.message));

                setLoading(false);
            })();
        }

        return false;
    };

    return (
        <Container>
            <Typography
                component="h1"
                mt="0"
                mb={alert ? '20px' : '40px'}
                fontSize="40px"
                fontWeight="100"
            >
                Login
            </Typography>

            {alert &&
                <Alert
                    severity="error"
                    onClose={() => setAlert(null)}
                    sx={{
                        mb: '20px',
                    }}
                >
                    {alert}
                </Alert>
            }

            <TextField
                label="Email"
                variant="outlined"
                sx={{
                    width: '300px',
                    mb: '30px'
                }}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onKeyUp={(e) => e.key === 'Enter' && requestLogin()}
            />
            <TextField
                label="Password"
                type="password"
                variant="outlined"
                sx={{
                    width: '300px',
                    mb: '30px',
                }}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onKeyUp={(e) => e.key === 'Enter' && requestLogin()}
            />
            <Button
                sx={{
                    display: 'block',
                    margin: '0 auto',
                    mb: '30px',
                }}
                onClick={() => navigate('/account/register')}
            >
                Don't have an account?
            </Button>
            <Button
                variant="contained"
                onClick={requestLogin}
            >
                Login
            </Button>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Container>
    );
}